import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root{
  // --themeclr: #2cc2a4;
  --themeclr: #fff;
  --second: #181934 
}
  * {
    font-family: 'Poppins', sans-serif !important;
  }
  
  body {
    background-color: var(--second) !important;
  }
  .theme-btn:hover,.confirm-expert-mode:hover{
     color:var(--themeclr) !important;
     background-image: linear-gradient(23deg,#7154fa,#25cd9c) !important;
     opacity:1 !important;
  }
  a{
    // color:var(--themeclr) !important;
    // background:var(--second) !important
    text-decoration:none  !important
  }
  a[href="/swap"]:hover,a[href="/liquidity"]:hover,a[href="/farms"]:hover
  ,a[href="/pools"]:hover,a[href="/farms/history"]:hover,a[href="/pools/history"]:hover{
     color: #fed136 !important;
  // background-image: linear-gradient(135deg,#846FF4 0%,#F17674 100%);
  // -webkit-text-fill-color: transparent;
  // background-clip: text;
  
  // text-fill-color: transparent;
}
  }
  button[scale="sm"]:hover {
    background: var(--second);
    color: var(--themeclr) !important;
    opacity:1 !important
  }
  a[rel="noreferrer noopener"]:hover{
    color:gray !important
  }
  .blacktext *{
    color:#000;
    fill:#000
  }
  nav > div:first-child {
    max-width: 100% !important;
    width: 100%;
    justify-content: space-between;
  }
  .theme-btn{
    color:#000
  }
  .togglewrap > div[scale="sm"]{
    background:var(--themeclr)
  }
  button[aria-label = "Hide or show expandable content"]{
    color: var(--themeclr) !important 
  }
 
    img {
      height: auto;
      max-width: 100%;
    }
    .fa, .far, .fas {
      font-family: "Font Awesome 5 Free" !important;
    }
    .fab {
      font-family: "Font Awesome 5 Brands" !important;
    }
  .dnone{
  display:none;
  }
  .fixedheader a {
    color: #000;
  }
  .fixedheader svg[color="textSubtle"]{
    fill: #000
  }
  .fixedheader button:hover svg{
    fill: #fff
  }
  .fixedheader div[data-popper-escaped ="false"] a {
    color: #fff;
  }
  nav{
    background: transparent !important;
    width: 100% !important;
    padding-right:5% !important;
    // max-width: 1200px;
    margin: 0 auto !important;
    position: relative !important;
    top: 0px !important;
    gap:15px;
    height: 77px !important;
  }
  nav + div {
    margin-top: 33px !important;
}
.footerLogo img {
 max-width:150px !important;
}
.swap_bg h2, .liquiditysec h2, .farm_page_bg h1 {
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(135deg, #846FF4 0%, #F17674 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
nav > div {
  align-items: center;
}
  #swap-currency-input div, #swap-currency-input input, #swap-currency-input input::placeholder,
  #swap-currency-output div, #swap-currency-output input, #swap-currency-output input::placeholder {
    color: var(--themeclr) !important;
    opacity: 1 !important;
}

#swap-currency-input button svg{
  fill: var(--themeclr) !important;
}

#swap-currency-output button svg{
  fill: var(--themeclr) !important;
}
div:before{
  background: var(--themeclr) !important;
}
#join-pool-button:hover{
  opacity: 1 !important;
  background: var(--themeclr) !important;
}
.setting_title h2 {
  color:var(--themeclr);
}

.navWrapperMain {
  background-color: rgb(238, 238, 238) !important;
  box-shadow: rgba(133, 133, 133, 0.1) 0px -2px 0px -8px inset;
  border-top: 0px solid rgb(129, 128, 224);
  border-bottom: 1px solid rgb(129, 128, 224);
  margin-top: -1px;
}
.nav_wrapper{
  position: relative;
max-width: 1200px;
margin: 0 auto;
}
.nav_wrapper > div{
  background: transparent !important;
}
.pancake:nth-child(2){
  dispaly:none !important;
  visibility: hidden;
}
.pancake:nth-child(3){
  dispaly:none !important;
  visibility: hidden;
}
.butter {
  dispaly:none !important;
  visibility: hidden;
}
.pancake:nth-child(1) {
	background: var(--themeclr);
	box-shadow: 0 1.5px 0 1.5px #8a8daf ;
}
.connect_wallet_heading{
  color: var(--themeclr) !important;
}
a.bnbscan:hover{
  color: var(--themeclr) !important;
  opacity:0.8
}
.hover_button:hover.hover_button div{
  color: var(--themeclr) !important;
}
.token-amount-input , input{
  color: var(--themeclr) !important;
}
#import-pool-link{
  color:var(--themeclr)
}
.headong h2{
 color:var(--themeclr)
}
.open-currency-select-button:hover.open-currency-select-button div {
  color:var(--themeclr) !important;
}
#add-liquidity-input-tokena div, #add-liquidity-input-tokenb div {
color:var(--themeclr);
}
#token-search-input{
  color:var(--themeclr);
}
.select_ic h2 {
  color:var(--themeclr);
}
.light_card_content div {
  color:var(--themeclr);
}
.bottom_conrtnet div{
  color:var(--themeclr) !important;
}
.select_color div {
  color:var(--themeclr);
}
.select_color svg {
  fill:var(--themeclr);
}
.input_new input{
 color:var(--themeclr);
}
#farms-table > table > tbody > tr > td > div{
color:#000;
}
.star_hidden {
	visibility: hidden;
}
.dog_img img{
 width:80%;
}
.font_light {
	font-weight: lighter !important;
	font-size: 17px;
	line-height: 26px;
}
.clas_img_img img{
	width: 70%;
	margin: 11px auto;
	display: block;
}
h2.text_c {
	text-align: center;
}
.justifua {
	justify-content: center;
}
.rowContent{
  display: flex;
  margin-top: 30px;
}
.rowContent.top50{
  margin-top: 50px;
}
.grid4{
  width: 40%;
  max-width: 452px;
  padding: 0 15px
}
.grid6{
  width: 50%;
  max-width: 678px;
  padding: 0 15px
}
.grid8{
  width: 60%;
  max-width: 700px;
  padding: 0 15px
}
.grid12 {
  width: 100%;
  max-width: 1152px;
  padding: 0 15px
}
.flexRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
}
.titleBox{
  margin-bottom: 25px;
}
.titleBox h2{
  font-size: 24px;
  margin-bottom: 0px;
}
.whiteBox{
  background: #403e3e;
  border-radius: 15px;
  padding: 15px;
  width: 100%;
}
.whiteBox.greyBox{
 background:transparent;
  border: 1px solid #FFFFFF;
  // box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  // backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  
  border-radius: 25px;
  padding: 1px;
  z-index:2;
  height:100%;
  cursor:pointer;
}
.whiteBox.greyBox:hover

{
  background: linear-gradient(114.02deg, #1167F9 21.14%, #FE82DB 50%, #13CCEC 60%);
box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
backdrop-filter: blur(20px);
border: 1px solid transparent;
}
.inner_bg
{
  border-radius: 25px;
  padding:25px;
  height:100%;

  background: linear-gradient(114.02deg, rgba(94, 89, 89, 0.29) 21.14%, rgba(94, 89, 89, 0) 100%);

}
.whiteBox.greyBox:hover .inner_bg
{
background-color:#000 !important;
}

.whiteBox.greyBox .listBox{
  font-weight: 700;
}
.whiteBox.greyBox .listBox h3{
  font-weight: 700;
}
.whiteBox.greyBox .titleBox h2 {
  color: var(--themeclr);
  font-size: 20px;
  font-weight: 700;
}
.whiteBox.greyBox .purbleRoundBg{
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0px;
  background-color: var(--themeclr) ;
  padding: 12px 40px 12px 40px;
  color: #1C1B19 ;
}
.purbleRoundBg {
    background: #d1d6ff;
    color: var(--themeclr);
    padding: 8px 20px;
    border-radius: 50px;
}
.listBox span{
  color: #f2f2f2 !important;
  font-weight:400 !important;
  font-size:14px !important;
}
.listBox h3{
  color: #f2f2f2 !important;
  font-weight:400 !important;
  font-size:14px !important;
  margin: 0;
}
.roadrTreeImg {
    width: 100%;
    border-radius: 15px;
}
.treeContent {
    position: relative;
}
.buttonAbsolute {
    position: absolute;
    top: 85%;
    left: 30px;
    z-index: 1;
}
.greenButton{
  background: #4e9c66;
  color: var(--themeclr);
  padding: 8px 18px;
  border-radius: 8px;
  transition: .5s;
}
.greenButton:hover{
  background: var(--themeclr);
}
.grid5{
  width: 100%;
  max-width: 425px;
  padding: 0 15px;
}
.grid2{
  width: 100%;
  max-width: 302px;
  padding: 0 15px;
}
.buttonGroupFlex {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.greenStakeButton button{
  background: #519c67;
}
.buttonGroupFlex button img{
  margin-right: 7px;
}
.whiteBox.tvlBox {
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.whiteBox.tvlBox.tvlBoxFlexStart{
  justify-content: flex-start;
  padding-top: 30px;
}
.tvlBox h3{
  font-size: 24px;
  margin-bottom: 0px;
}
.tvlBox h2{
  font-size: 38px;
  margin: 20px 0;
  color: var(--themeclr);
}
.tvlBox p{
  font-size: 18px;
  margin-bottom: 0px;
  color: #4e9c66;
}
.farmStakBox{
   padding-top: 30px;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.farmStakBox h2{
  color: var(--themeclr);
  font-size: 20px;
  font-weight: 700;
}
.flexFs{
  display: flex;
}
.fsBox {
    width: 50%;
    margin-top: 20px;
}
.fsBox h3{
  color: #f2f2f2 !important;
  font-weight:400 !important;
  font-size:14px !important;
  text-transform: uppercase;
  line-height: 53px;
}
.fsBox h5, .fsBox p{
  color: #f2f2f2 !important;
  font-weight:400 !important;
  font-size:14px !important;
  line-height: 45px;
}
.titleImg{
  margin-top: 15px;
}
.abooutPageTitle{
  font-size: 36px;
  margin-bottom: 15px;
  text-align: center;
}
.aboutContentTitle{
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: 30px;
  text-align: center;
}
.aboutButton{
  margin-left: 8px;
}
.aboutButton button{
  background: #e78638;
  color: var(--themeclr);
  border-color: #e78638;
}
.purpleButton{
  background: var(--themeclr);
  color: var(--themeclr);
  padding: 8px 18px;
  border-radius: 8px;
  transition: .5s;
}
.purpleButton:hover{
  background: #4e9c66;
}
.blockButton .purpleButton{
  margin-top: 25px;
  display: block;
  width: 100%;
  text-align: center;
   padding: 12px 18px;
}
.treeDayBox {
  text-align: center;
}
.treeDayBox h3{
  font-size: 20px;
  color: var(--themeclr);
  margin-bottom:12px;
}
.artTree{
  max-height: 190px;
}
.fsBox{
  position:relative;
}

.earnBox h3 a i span{
  display: none;
}
.grid3{
  width: 32%;
  max-width: 400px;
  padding: 0 15px
}
.earnBox h2{
  font-size: 20px;
  margin: 20px 0;
  color: #4e9c66;
  font-weight: 500;
}
.earnBox h3{
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 0px;
}
.earnBox h3 a{
  font-size: 16px;
  background: var(--themeclr);
  color: var(--themeclr);
  padding: 8px 15px;
  border-radius:30px;
  display:inline-block;
  transisation:.5s;
}
.earnBox h3 a:hover{
  background: #4e9c66;
}
.tree_row_secton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.referralContent h4 {
  font-size: 16px;
  font-weight: 400;
  background: #4d9c65;
  padding: 14px 10px;
  border-radius: 30px;
  color: var(--themeclr);
  margin: 15px 0;
}
.about_content h1{
	font-size: 36px;
	margin-bottom: 22px;
	color: var(--themeclr);
}
.about_content.referralContent h1{
  text-align: left;
  font-size: 24px;
  color: var(--themeclr);
  line-height: 36px;
  margin: 15px 0 0;
}
.referralContent p {
  text-align: left;
}
.about_content p {
	color: var(--themeclr);
	line-height: 26px;
	font-size: 17px;
	font-weight: lighter !important;
}
ul.referralContent_list {
  padding-left: 30px;
  list-style: circle;
}
.referralContent_list li {
  color: var(--themeclr);
	line-height: 26px;
	font-size: 16px;
  margin-bottom: 10px;
}
.space_dog .logo_home {
	width: 81px;
}
.space_dog h3 {
	text-align: center;
	color: var(--themeclr);
}
.about_content {
	margin: 42px auto;
  text-align: center;
}
.justifua {
  margin-top: 15px;
}
.grid_11 {
	width: 100%;
	max-width: 460px;
	padding: 0 15px;
}
.cololr_green{
  color:var(--themeclr) !important;
}
.cololr_green svg{
  fill: var(--themeclr);
}
.bg_white {
	background: var(--themeclr);
	border: none;
}
.top_headein_ss {
	text-align: center;
	padding: 19px 0px;
	font-size: 20px;
	color: var(--themeclr);
}
.clas_img_img {
	background: var(--themeclr);
	border-radius: 10px;
	margin: 0 15px;
	margin-top: 26px;
}
.headin_new{
  font-size: 36px;
  margin-bottom: 0;
}
.partnerLogoMain{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.partnerLogo{
  max-width: 80px;
  max-width: 200px;
  margin: 0 30px;
}
.homeHarvestCard {
    position: relative !important;
    margin-bottom: -50px;
    top: -97px !important;
}
.aboutPageBgTop{
  background: url("../images/aboutbg.jpg") no-repeat scroll center bottom;
  background-size: cover;
  min-height: 400px;
  font-size: 0;
  position: relative;
  margin-bottom: 75px;
}
.wriseAboutLogo{
  background: url("../images/aboutLogo.jpg") no-repeat scroll center bottom;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  bottom: -75px;
  left: 50%;
  margin-left: -75px;
  max-width: 100%;
}
.socialFooter i span{
  display: none;
}
.socialFooter{
  padding: 8px 0;
  background-image: linear-gradient(23deg,#25cd9c, #7154fa);
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  display: none;
}

.socialFooter ul{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}
.socialFooter ul li{
  margin: 7px;
}
.socialFooter ul li a{
  padding: 6px;
  font-size: 20px;
  color: #fff;
  transition: .5s;
}
.socialFooter ul li a:hover{
  color: #29c6a1;
}
.farm_page_bg {
  background: var(--second);
}
.farm_page_bg > div:first-child {
  // border-bottom: 1px solid #E7E3EB;
}
.farm_page_bg h2{
  color:#fff !important
}
.connect_modal button {
  opacity: 1 !important;
}
.connect_modal button.hover_button:hover div {
  color: var(--themeclr) !important;
}
.earn_section .grid3 {
	width: 25%;
}
.refer_section_borrn{
  width: 100%;
}
.refer_section_borrn  .input_icon {
	display: flex;
	border: 1px solid #ccc;
	padding: 4px 7px;
	width: 100%;
	border-radius: 5px;
  margin: 13px auto;
}
.refer_section_borrn  .input_icon input {
	width: 100%;
  color: var(--themeclr);
	border: none;
}
.refer_section_borrn  .input_icon i{
	color: #4e9c66;
  cursor: pointer;
}
.count_section  {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}
.count_section p {
	margin: 7px 0px;
}
.count_section p.color_green {
	color: #4e9c66;
}
.custom_menubutton > div {
  background-color: transparent;
  border: none;
}
.custom_menubutton > div a {
  padding: 4px 4px 0px;
  color: rgb(76, 84, 155);
  font-size: 16px;
  font-weight: 400;
  position: relative;
  height: 42px;
  margin-right: 20px;
  background-color: transparent;
}
.custom_menubutton > div a.active {
  background-color: transparent;
  color: rgb(76, 84, 155);
  font-weight: 600;
}
.custom_menubutton > div a.active::before {
  content: "";
  position: absolute;
  bottom: -1px;
  height: 4px;
  width: 100%;
  background: rgb(248,147,30) none repeat scroll 0% 0% !important;
  border-radius: 2px 2px 0px 0px;
}
h2#info-overview-title {
  font-size: 64px;
  position: relative;
  margin: 16px 0;
}
.info_search {
  position: absolute;
  top: 6px;
  right: 15px;
}
.info_search input {
  height: 32px;
  font-size: 14px;
}
h1 {
  margin-top: 32px;
}
.breadcrumbs {
  position: absolute;
  top: 114px;
  background: rgb(238, 238, 238);
  width: 100%;
  left: 0;
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid var(--themeclr);
  display: flex;
  justify-content: center;
  align-items: center;
}
.breadcrumbs li{
  margin-right:20px;
}
.breadcrumbs li a{
  font-size: 16px;
  font-weight: 400;
  padding: 4px 4px 0px 4px;
  height: 42px;
}
.breadcrumbs li a:hover {
  color: var(--second) !important;
  // text-decoration: underline;
}
.breadcrumbs li:nth-child(even){
  display: none;
}
.breadcrumbs li div{
  position: relative;
  font-weight: 600;
}
.breadcrumbs li div:before{
  content: "";
  position: absolute;
  bottom: -10px;
  height: 4px;
  width: 100%;
  background-color: var(--themeclr);
  border-radius: 2px 2px 0 0;
}

.voting_inp:checked {
  background-color: var(--themeclr);
}
.text_left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.align-center {
  align-items: center;
}
.copied_div {
  position: relative;
}
.copied_txt {
  position: absolute;
      top: 15px;
}
.bannerTop{
  min-height: 100vh;
  padding: 150px 0;
  background: url("../images/banner_bg_new.png") no-repeat scroll right top;
  background-size:cover;
  background-position: top 0px left 0px;
  background-color:#000000 !important;
  display:flex;
  align-items:center;
}
.bannerToptwo
{
  padding: 0px 0 150px 0;
  // background: url("../images/banner_bg_new.png") no-repeat scroll right top;
  background-size:cover;
  background-position: top 0px left 0px;
  background-color:#000000 !important;
  position:relative;
}

@media screen and (min-width:992px)
{
  .bannerTop
  {
    margin-top:-120px !important;
  }
}
// .bannerTop::before{
//   content: "";
//   position: absolute;
//   right: 0;
//   top: -113px;
//   background: url("../images/img3home1.png") no-repeat scroll right top;
//   width: 941px;
//   height: 694px;
// }
.bannerTop h1{
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  line-height:1.7;
  color: var(--themeclr);
}
.bannerTop h1 span{
  color: var(--themeclr);
  font-size: 28px;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 52px;
  display: block;
}
.bannerTop h6{
  color: var(--themeclr);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin: 25px 0 20px;
}
.bannerAppButton{
  z-index : 1000;
}
.bannerAppButton a{
  margin-right: 20px;
  display: inline-block;
  z-index : 1000;
}
nav.fixedheader {
  position: fixed !important;
  width: 100% !important;
  justify-content: space-between;
  margin: auto !important;
  left: 0;
  right: 0;
  top:0 !important;
  background: #d7dfef !important;
  max-width: 100%; 
  z-index:9999 !important;
  // padding: 0 220px;
}

text-white{
  color:"#fff"
}
nav a{
  text-decoration: none !important;
  font-weight:500;
  letter-spacing:1px
}
iframe{
  display: none;
}
a#join-pool-button {
  color: var(--second);
}
a#join-pool-button svg{
  fill: var(--second);
}
#btn_hover:hover, span a:hover{
// color:inherit !important
}
a#join-pool-button:hover {
  background: var(--second) !important;
  color: var(--themeclr);
}
a#join-pool-button:hover svg {
  fill: var(--themeclr);
}
.whiteBox.earnBox.whiteShadowBox{
    box-shadow: 0px 0px 54px 0px rgba(129.00000000000003, 129.00000000000003, 224, 0.13);
    padding: 33px 22px 46px 28px;
    margin-bottom: 40px;
}
.whiteBox.earnBox.whiteShadowBox h3{
  font-size: 20px;
  font-weight: 800;
  line-height: 32px;
  color: var(--themeclr);
  font-weight: 700;
  margin-bottom: 10px;
}
.whiteBox.earnBox.whiteShadowBox h4{
  font-size: 17px;
  font-weight: 400;
  color: var(--themeclr);
  margin-bottom: 10px;
  line-height: 28px;
}
.whiteBox.earnBox.whiteShadowBox h2{
  font-size: 17px;
  font-weight: 400;
  color: var(--themeclr);
  margin-bottom: 10px;
  line-height: 28px;
}

.whiteBox.earnBox.whiteShadowBox h5 {
  color: var(--themeclr);
  font-size: 17px;
  font-weight: 700;
  line-height: 28px;
}
.whiteBox.earnBox.purpleBox{
  background-color: var(--themeclr);
  padding: 50px 20px 65px 29px;
  border-radius: 5px 5px 5px 5px;
}
.whiteBox.earnBox.purpleBox h3{
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
.whiteBox.earnBox.purpleBox h2{
  color: #000;
  font-size: 17px;
  font-weight: 400;
}
.whiteBox.earnBox.purpleBox h4{
  color: #000;
  font-size: 17px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
}
// .imgLeftAlign {
//   margin-bottom: 35px;
// }
.sparcoDetail{
  position: relative;
  background-color:#000;
  padding-bottom:30px;
}
.sparcoDetail:before
{
  content:"";
  position: absolute;
width: 150px;
height: 160px;
left: -35px;
top: -80px;
border-radius: 50%;
    z-index: 1;

background: radial-gradient(50% 50% at 50% 50%, rgba(17, 103, 249, 0.25) 0%, rgba(17, 103, 249, 0.045) 100%);
}

.container{
  width: 90% !important;
  max-width: 1200px;
}
.hbLeft h2{
  color: #383c5e;
  font-size: 35px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 52px;
}
.hbLeft p{
  color: #383c5e;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  margin: 5px 0px 20px 0px;
}
.homeBanking{
  padding: 50px 0;
}
.sparcoProcess{
  padding: 0px 0 80px;
  margin-top:50px
}
.sparcoProcess h2{
  color: var(--themeclr);
  font-size: 35px;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 52px;
  text-align: center;
}
.sparcoProcess h6{
  margin: 25px 0px 77px 1px;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: var(--themeclr);
}
.cardWhite {
  box-shadow: 0px 0px 56px 0px rgba(128.99999999999997, 128, 224, 0.13);
  margin: 22px 60px 20px 15px;
  padding: 26px 30px 43px 38px;
  width: 85%;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  background:#3d3d3d
}
.cardWhite.cardPurple{
  background: var(--themeclr);
}
.cardNumber{
  background: url("../images/BG-copy.png") no-repeat scroll 0 0;
  width: 117px;
  height: 109px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: 700;
  color: var(--themeclr);
}
.cardWhite h4{
  font-size: 20px;
  color: var(--themeclr);
  margin: 15px 0;
}
.cardWhite.cardPurple h4{
  color: #000;
}
.cardWhite p{
  color: var(--themeclr);
  font-size: 17px;
  line-height: 28px;
}
.cardWhite.cardPurple p{
  color: #000;
}
.homeNews h2{
  color: var(--themeclr);
  font-size: 35px;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 52px;
  text-align: center;
}
.homeNews h6{
  margin: 25px 0px 20px 1px;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: var(--themeclr);
}
.blogListHome{
  padding-top: 40px;
}
.blogListHome ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.blogListHome ul li{
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 18px;
  margin-bottom: 18px;
}
.blogListHome ul li h3{
  font-size: 20px;
  color: var(--themeclr);
  font-weight: 700;
  margin-bottom: 15px;
}
.blogListHome ul li h3 small{
  font-size: 16px;
  color: var(--themeclr);
  margin-top: 10px;
}
.blogListHome ul li p{
  color: var(--themeclr);
  line-height: 28px;
  margin-bottom: 18px;
}
.blogListHome ul li a{
  color: var(--themeclr);
  font-weight: 700;
}
.footerTop{
  background: #000;
  padding: 40px 0px 25px 0px;
  border-top:1px solid #151414;
}
.flexFooter {
  display: flex;
}
.gridFooter{
  width: 20%;
}
.gridFooter.widthLarge{
  width: 75%;
}
.gridFooter.WidthSmall{
  width: 25%;
  padding-left: 30px;
}
.gridFooter ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.d-inlineblock li{
  display: inline-block;
  margin-right:15px;
}
.footerBottom{
  background: #121212;
  padding: 15px 0;
}
.footerBottom p{
  max-width: 90%;
  text-align: center;
  margin: 0 auto;
  font-size:13px;
  color: var(--themeclr);
  line-height: 28px;
}
.gridFooter{
  padding: 0 15px;
}

.gridFooter h3{
  color: var(--themeclr);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
}
.gridFooter ul li a{
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: var(--themeclr) !important;
}
.gridFooter ul li a:hover{
  color:#29c6a1 !important
}
.footerLogo{
  margin-bottom: 10px;
}
.gridFooter p{
  color: var(--themeclr);
  margin: 0;
  font-size:14px !important;
  line-height: 24px;
  font-weight:400 !important;
}
@media(max-width:1090){
  .footerTop{
    padding:167px 0px 36px 0px !important
  }
}
@media screen and (max-width: 900px) {

.partnerLogoMain{
  flex-wrap: wrap;
}
.partnerLogo {
	margin: 7px 9px;
}
.about_content h1 {
	font-size: 30px;
}
.titleBox h2 {
	font-size: 20px;
}
.farmStakBox h2 {
	font-size: 20px;
}
.fsBox h3 {
	color: #f2f2f2 !important;
  font-weight:400 !important;
  font-size:14px !important;
}
.tvlBox h3 {
	font-size: 20px;
}
.tvlBox h2 {
	font-size: 30px;
}
.earnBox h2 {
	font-size: 15px;
}
.bMPnRQ .mobile-icon {
	width: 130px;
}
.info_search {
  position: relative;
  top: unset;
  right: unset;
  justify-content: center;
  display: flex;
}
.nav_wrapper {
  background: var(--themeclr);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.info_search {
  margin: 5px 10px 10px;
}
}

@media screen and (max-width: 780px) {
  .homeHarvestCard {
    top: -34px !important;
}


  .rowContent {
    flex-wrap:wrap;
  }
  .grid3 {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .grid8 {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .grid4 {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .roadrTreeImg {
    margin-top: 50px;
  }
  .rowContent > div{
    margin: 14px 0px;
  }
  .bMPnRQ .mobile-icon {
    width: 134px;
  }
  .clas_img_img img {
    width: 46%;
  }
  .about_content h1 {
    font-size: 29px;
  }
  .fsBox:first-child::before {
    right: 6px;
    display:none;
  }
  .flexFooter{
    flex-direction: column;
  }
  .footerTop{
    padding-top: 60px;
  }
  .gridFooter.widthLarge{
    width: 100%;
  }
  .gridFooter.WidthSmall{
    padding-left: 0;
    width: 100%;
    margin-top: 30px;
  }
  .gridFooter{
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
}
@media(max-width:767px){
  .colrev_md .row
  {
    flex-direction:column-reverse;
  }
  #homepage-hero
  {
    display:none;
  }
  .bannerTop
  {
    margin-top:-120px !important;
  }
  .bannerTop {
    min-height: 100vh;
    padding: 0px 0;
}
}

@media(min-width:768px)
{
  .img_flex_md
  {
    display:flex;
    align-items:center;
  }
  .bannerTop
  {
    margin-top:-120px;
  }
  .bannerToptwo:before
{
  content:"";
  position: absolute;
width: 400px;
height: 400px;
left: -180px;
top: -100px;
border-radius:50%;

background: radial-gradient(50% 50% at 50% 50%, rgba(17, 103, 249, 0.25) 0%, rgba(17, 103, 249, 0.045) 100%);
}
}
@media(max-width:575px){
  section.footerBottom {
    margin-bottom: 50px;
  }
  nav{
    width:100% !important
  }
  .socialFooter ul li i {
    font-size: 16px;
  }
  .socialFooter {
    padding: 0px 0;
  }
  nav button {
    font-size: 12px !important;
    padding: 10px !important;
    margin-right: 0 !important;
 }
}



.text_color_farm:hover{
  color:var(--themeclr) !important;
  opacity:0.9
}

button.theme-btn {
  background-image: linear-gradient(23deg,#25cd9c, #7154fa);
  color: #fff;
    border: 1px solid #fff !important;
    white-space:nowrap
}
.liner_tect_sm
{
  background: linear-gradient(to right, #137DF6 5%,#13CCEC 30%,#FE82DB 45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.elementor-heading-title-seoc
{
  color:#fff;
  font-size:20px;
  line-height:1.7;
  text-transform:uppercase;
  margin-top:20px;
  margin-bottom:10px;
}
.secon_sec_desc
{
  color: var(--themeclr);
    font-family: 'Poppins',sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin: 0px 0 20px 0px;
}
.img_text_div_home
{
  display:flex;
}
.flex_text_hm
{
  display:flex;
  flex-direction:column;
  margin-left:20px;
}
.img_hoem_icon
{
  max-width:80px;
}
.hone_sec_card_title
{
  color:#fff;
  font-size:16px !important;
  font-weight:500 !important;
  line-height:1.5;
}
.hone_sec_card_desc
{
  color:#9d9a9a !important;
  font-size:14px !important;
  font-weight:400 !important;
  margin-top:15px;
  margin-bottom:15px;
}
.spar_row_mb .mb-4
{
  margin-bottom:2rem !important;
}
`
export default GlobalStyle
